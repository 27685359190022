import { AddOn } from './../../models/addons.model';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BaseService } from '../base/base.service';
import { AddOns } from "./addOns.types";

@Injectable()
export class AddOnsService extends BaseService {
  addonCaches$: Observable<AddOn[]>;

  get(): Observable<AddOn[]> {
    if (!this.addonCaches$) {
      this.addonCaches$ = this.fetchAddOns().pipe(shareReplay());
    }

    return this.addonCaches$;
  }

  isActive(addon: AddOns): Observable<boolean> {
    return this.get()
      .pipe(
        map(dataComplement => {
          return dataComplement.find(complement => complement.type === addon)?.isActive;
        }),
      );
  }

  private fetchAddOns(): Observable<AddOn[]> {
    return this.http.get<AddOn[]>(`${this.settings.get.addOnsApi}`);
  }

  accountId(addon: string): Observable<string> {
    return this.get()
      .pipe(
        map(dataAccount => {
          return dataAccount.find(complement => complement.type === addon)?.data?.account;
        }),
      );
  }
}
