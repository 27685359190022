import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifyHelper, ShareByEmailViewModel } from '@iris/iris-base';
import { toBlob } from 'html-to-image';
import * as moment from 'moment';
import { catchError, tap } from 'rxjs/operators';
import { AsyncOperationType } from 'src/lib/models/async.model';
import { v4 as uuidv4 } from 'uuid';
import { AsyncOperationService } from '../async/async-operation.service';
import { BaseService } from '../base/base.service';
import { FileDownloaderService } from '../file/file-downloader.service';
import { SettingsService } from '../settings/settings.service';
import { TransactionFilters } from '../transaction/transaction.types';
import { ExportFileViewModel, ExportTransactionFilters, ExportType } from './export.types';

@Injectable()
export class ExportService extends BaseService {
  private RECEIPT_PREFIX = 'ComprobanteIRIS';
  private QUERY_PREFIX = 'ConsultaIRIS';

  constructor(
    protected http: HttpClient,
    protected settings: SettingsService,
    private asyncOperationService: AsyncOperationService,
    private notifyService: NotifyHelper,
  ) {
    super(http, settings);
  }

  exportImage(el: HTMLElement): Promise<void> {
    return new Promise<void>((res, rej) => {
      toBlob(el)
        .then(blob => {
          const downloadService = new FileDownloaderService();
          downloadService.downloadfile(
            blob,
            `${this.RECEIPT_PREFIX}-${moment().format('DDMMYYYY')}.jpg`,
          );
          res();
        })
        .catch(err => {
          rej(err);
        });
    });
  }

  exportTransactionReceipt(
    encodedKey: string,
    type: ExportType,
  ): Promise<void> {
    return new Promise<void>((res, rej) => {
      this.http
        .get(
          `${this.settings.get.exportsApi}/transaction/${encodedKey}/${type}`,
          {
            responseType: 'text',
          },
        )
        .toPromise()
        .then(base64 => {
          this.outputExport(
            base64,
            type,
            `${this.RECEIPT_PREFIX}-${moment().format('DDMMYYYY')}.pdf`,
          );
          res();
        })
        .catch(() => rej());
    });
  }

  exportTransactionHistory(
    filters: ExportTransactionFilters,
    type: ExportType,
  ) {
    filters.uuid = uuidv4();

    return new Promise<void>((res, rej) => {
      this.http
        .get(`${this.settings.get.exportsApi}/transaction/${type}`, {
          params: filters as any,
        })
        .toPromise()
        .then(() => {
          this.waitAsyncResponse(
            filters.uuid,
            AsyncOperationType.EXPORT_TRANSACTIONS_DETAILS,
          )
            .then(base64 => {
              this.showEncryptionAlert(type);
              const startAt = moment(filters.startAt).format('DDMMYYYY');
              const endAt = moment(filters.endAt).format('DDMMYYYY');
              const fileExtensions = type === ExportType.XLS ? '.xlsx' : '.pdf';

              this.outputExport(
                base64,
                type,
                `${this.QUERY_PREFIX}-${startAt}-${endAt}${fileExtensions}`,
              );
              res();
            })
            .catch(() => rej());
        })
        .catch(() => rej());
    });
  }

  exportPendingTransactions(filters: any, type: ExportType) {
    filters.uuid = uuidv4();

    return new Promise<void>((res, rej) => {
      this.http
        .get(`${this.settings.get.exportsApi}/transaction/pending/${type}`, {
          responseType: 'text',
          params: filters as any,
        })
        .toPromise()

        .then(() => {
          this.waitAsyncResponse(
            filters.uuid,
            AsyncOperationType.EXPORT_PENDING_TRANSACTIONS,
          )
            .then(base64 => {
              this.showEncryptionAlert(type);
              const startAt = moment(filters.startAt).format('DDMMYYYY');
              const endAt = moment(filters.endAt).format('DDMMYYYY');
              const fileExtensions = type === ExportType.XLS ? '.xlsx' : '.pdf';

              this.outputExport(
                base64,
                type,
                `${this.QUERY_PREFIX}-${startAt}-${endAt}${fileExtensions}`,
              );
              res();
            })
            .catch(() => rej());
        })
        .catch(() => rej());
    });
  }

  outputExport(base64: string, type: ExportType, fileName: string) {
    const ext = type.toLocaleLowerCase();
    const byteString = atob(base64);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: `application/${ext}` });

    const downloadService = new FileDownloaderService();
    downloadService.downloadfile(blob, fileName);
  }

  sendTransactionReceipt(
    encodedKey: string,
    type: ExportType,
    emailOptions: ShareByEmailViewModel,
  ): Promise<string> {
    return this.http
      .post(
        `${this.settings.get.exportsApi}/transaction/${encodedKey}/${type}/email`,
        { ...emailOptions },
        {
          responseType: 'text',
        },
      )
      .toPromise();
  }

  sendTransactionHistory(
    filters: TransactionFilters,
    type: ExportType,
    emailOptions: ShareByEmailViewModel,
  ): Promise<string> {
    return this.http
      .post(
        `${this.settings.get.exportsApi}/transaction/${type}/email`,
        { ...filters, emailReport: { ...emailOptions } },
        { responseType: 'text' },
      )
      .pipe(tap(() => this.showEncryptionAlert(type)))
      .toPromise();
  }

  sendPendingTransactions(
    filters: any,
    type: ExportType,
    emailOptions: ShareByEmailViewModel,
  ): Promise<string> {
    return this.http
      .post(
        `${this.settings.get.exportsApi}/transaction/pending/${type}/email`,
        { ...filters, emailReport: { ...emailOptions } },
        { responseType: 'text' },
      )
      .pipe(tap(() => this.showEncryptionAlert(type)))
      .toPromise();
  }

  private async waitAsyncResponse(
    uuid: string,
    type: AsyncOperationType,
  ): Promise<string> {
    return new Promise((res, rej) => {
      this.asyncOperationService
        .listenAsyncResponse<ExportFileViewModel>(uuid, type)
        .pipe(
          catchError(e => {
            rej(e);
            throw e;
          }),
        )
        .subscribe(async r => {
          res(await this.downloadExport(r.id));
        });
    });
  }

  private async downloadExport(id: string): Promise<string> {
    return await this.http
      .get(`${this.settings.get.exportsApi}/${id}`, {
        responseType: 'text',
      })
      .toPromise();
  }

  private showEncryptionAlert(type: ExportType) {
    if (type === ExportType.PDF)
      this.notifyService.warning("Exports.Encryption.Message", "Exports.Encryption.Title");
  }
}
