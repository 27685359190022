import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IrisOtpConstants, OtpViewModel } from '@iris/iris-otp';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { Status, User, Transaction, Depositaccounts, CorporationResponseViewModel, UserList } from 'src/lib/models';
import { Card } from 'src/lib/models/card.model';
import { BaseService } from '../base/base.service';
import { PaginatedResponse } from '../base/pagination.model';
import { TransactionFilters } from '../transaction/transaction.types';
import { UserAdd, UserEdit, UserFilter } from './user.types';

@Injectable()
export class UserService extends BaseService {
  private userCaches$: any = {};
  depositAccountCache$: Observable<Depositaccounts>;

  get(
    filters: UserFilter = { status: [Status.Active] },
    cached?: boolean,
  ): Observable<User[]> {
    if (cached || cached === undefined) {
      const key = btoa(JSON.stringify(filters));
      const cache$ = this.userCaches$[key];

      if (!cache$) {
        this.userCaches$[key] = this.get(filters, false).pipe(shareReplay());
      }

      return this.userCaches$[key];
    }

    return this.http.get<User[]>(this.settings.get.userApi, {
      params: filters as HttpParams,
    });
  }

  getById(userId: string): Observable<User> {
    return this.http.get<User>(`${this.settings.get.userApi}/${userId}`);
  }

  create(model: UserAdd, otp: OtpViewModel): Observable<string> {
    return this.http.post(this.settings.get.userApi, model, {
      responseType: 'text',
      headers: {
        [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
        [IrisOtpConstants.OtpIdHeader]: otp.otpId,
      },
    });
  }

  update(
    userId: string,
    model: UserEdit,
    otp: OtpViewModel,
  ): Observable<string> {
    return this.http.put(`${this.settings.get.userApi}/${userId}`, model, {
      responseType: 'text',
      headers: {
        [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
        [IrisOtpConstants.OtpIdHeader]: otp.otpId,
      },
    });
  }

  massUpdate(models: UserEdit[], otp: OtpViewModel): Observable<string> {
    return this.http.put(this.settings.get.userApi, models, {
      responseType: 'text',
      headers: {
        [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
        [IrisOtpConstants.OtpIdHeader]: otp.otpId,
      },
    });
  }

  resetPassword(userId: string): Observable<string> {
    return this.http.put(
      `${this.settings.get.userApi}/${userId}/reset_password`,
      null,
      {
        responseType: 'text',
      },
    );
  }

  resetCache(): void {
    this.userCaches$ = {};
  }

  getCardEprepaid(userid: string, status: string): Observable<Card[]> {
    const params = new HttpParams();

    params.append('status', status);
    params.append('userId', userid);

    return this.http.get<Card[]>(`${this.settings.get.eprepaidApi}/cards`, {
      params
    });
  }

  getCardById(id: string) {
    return this.http.get(`${this.settings.get.eprepaidApi}/cards/${id}`).pipe(
      tap(() => {
        this.resetCache();
      })
    );
  }

  getCardTransactions(filters: TransactionFilters): Observable<PaginatedResponse<Transaction>> {
    return this.http.get<PaginatedResponse<Transaction>>(
        `${this.settings.get.eprepaidApi}/transactions`,
      {
        params: filters as any,
      },
    );
  }

  depositAccount(): Observable<Depositaccounts> {
    if (!this.depositAccountCache$) {
      this.depositAccountCache$ = this.fetchDepositAccount().pipe(shareReplay());
    }

    return this.depositAccountCache$;
  }

  private fetchDepositAccount() {
    return this.http.get<Depositaccounts>(
        `${this.settings.get.eprepaidApi}/depositaccounts`
    );
  }

  getAccountNumber(): Observable<CorporationResponseViewModel> {
    return this.http.get<any>(`${this.settings.get.eprepaidApi}/corporation`);
  }

  getUser(
    filters: UserFilter = { status: [Status.Active] }
  ): Observable<UserList[]> {
    return this.http.get<UserList[]>(`${this.settings.get.eprepaidApi}/cards/users`);
  }
}
