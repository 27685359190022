import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationError, Router } from '@angular/router';
import {
  NetworkErrorType,
  NetworkService,
  SpinnerComponent,
} from '@iris/iris-base';
import { AccountService, SessionService } from '@iris/iris-authentication';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { WalktroughService } from 'src/lib/services/walktrough/walktrough.service';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
  providers: [WalktroughService],
})
export class WrapperComponent implements OnInit, OnDestroy {
  spinnerComponent = SpinnerComponent;
  showWelcome = false;
  requiredWalkthrough: string[] = [];

  constructor(
    translate: TranslateService,
    private router: Router,
    private networkService: NetworkService,
    private sessionService: SessionService,
    private accountService: AccountService,
    private walktroughService: WalktroughService,
  ) {
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('es');
    translate.use('es');
  }

  ngOnInit() {
    if (this.sessionService.credentials?.token) {
      this.sessionService.activate();
      this.router.events
        .pipe(filter((e): e is NavigationError => e instanceof NavigationError))
        .subscribe(() => {
          this.networkService.networkError.next(NetworkErrorType.NoConnection);
        });
      this.verifyWalktrough();
    }
  }

  ngOnDestroy(): void {
    this.sessionService.inactivate();
  }

  @HostListener('window:unload')
  beforeUnloadHandler() {
    this.sessionService.inactivate();
  }

  verifyWalktrough() {
    this.accountService.me().subscribe((r: any) => {
      this.requiredWalkthrough = r.requiredWalkthrough;
      this.showWelcome = this.requiredWalkthrough.includes('WELCOME_PAGE');
    });
  }

  updateWalktrough(type: string) {
    this.showWelcome = false;
    const index = this.requiredWalkthrough.indexOf(type);
    if (index != -1) {
      this.requiredWalkthrough.splice(index, 1);
    }
    this.walktroughService
      .updateWalktrough(this.requiredWalkthrough)
      .subscribe();
  }

  get filteredUrlsForLoader(): string[] {
    return ['/dailybalances', '/statistics', '/aggregation'];
  }
}
