import {
  CardPaymentViewModel,
  DestinationAccount,
  MambuTransactionType,
  OperationType,
  Schedule
} from 'src/lib/models';
import { OrderedRequest } from '../base/ordered.model';
import { PaginatedRequest } from '../base/pagination.model';
import {Tag} from "@iris/iris-components";

export enum TransactionOrdering {
  DATE = 'DATE',
}

export enum BatchOrdering {
  CREATED_AT = 'CREATED_AT',
  PROCESSED_AT = 'PROCESSED_AT',
  TYPE = 'OPERATION',
  STATUS = 'STATUS',
  SCHEDULED_FOR = 'SCHEDULED_FOR',
}

export interface TransactionFilters
  extends PaginatedRequest,
  OrderedRequest<TransactionOrdering> {
  startAt?: string;
  endAt?: string;
  depositAccountId?: string[];
  transactionTypes?: MambuTransactionType[];
  userName?: string;
  tagId?: string;
  cardLastFourDigits?: string
}

export interface TransactionSummaryFilters extends TransactionFilters {
  uuid?: string;
}

export enum PendingTransactionOrdering {
  CREATED_AT = 'CREATED_AT',
  PROCESSED_AT = 'PROCESSED_AT',
  OPERATION = 'OPERATION',
  OPERATION_TYPE = 'OPERATION_TYPE',
  STATUS = 'STATUS',
  BANK_ID = 'BANK_ID',
  SCHEDULED_FOR = 'SCHEDULED_FOR',
}

export interface PendingTransactionFilters
  extends PaginatedRequest,
  OrderedRequest<PendingTransactionOrdering> {
  startAt?: string;
  endAt?: string;
  destinationAccountOwner?: string;
  userIds?: string[];
  banks?: string[];
  tags?: string[];
  status?: TransactionStatus;
  authorizationStatus?: string;
  transactionTypes?: TransferType[];
}

export interface PaymentTransactionFilters extends PaginatedRequest {
  startAt?: string;
  endAt?: string;
  details?: string;
  paymentTypes?: OperationType[];
  status?: TransactionStatus[];
  tagId?: string;
}

export interface TransferFilters extends PaginatedRequest {
  startAt?: string;
  endAt?: string;
  scheduledStartAt?: string;
  scheduledEndAt?: string;
  operation?: OperationType[];
  transferType?: TransferType[];
  status?: TransactionStatus[];
  periodicity?: string[];
  banks?: string[];
  depositAccountId?: string[];
  tagId?: string;
}

export enum BatchAuthorizationStatus {
  APPROVED = 'APPROVED',
  WAITING_AUTHORIZATION = 'WAITING_AUTHORIZATION',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  CANCELED = 'CANCELED',
}

export interface TransactionFiltersByDepositAccount {
  startAt?: string;
  endAt?: string;
  depositTransactionTypes?: string;
}

export interface TransactionSummaryFilters {
  startAt?: string;
}

export enum SchedulePeriodicity {
  SPECIFIC = 1,
  WEEKLY = 2,
  BIWEEKLY = 3,
  MONTHLY = 4,
}

export enum TransferType {
  OWN_ACCOUNT = 1,
  SAME_BANK = 2,
  OTHER_BANK = 3,
  PSE = 4,
  PRODUCT_TRANSFERS = 6,
}

export enum Operation {
  TRANSACTIONS_OWN_ACCOUNTS = 'TRANSACTIONS_OWN_ACCOUNTS',
  TRANSACTIONS_ACCOUNTS_SAME_BANK = 'TRANSACTIONS_ACCOUNTS_SAME_BANK',
  TRANSACTIONS_ACCOUNTS_OTHER_BANK = 'TRANSACTIONS_ACCOUNTS_OTHER_BANK',
  TRANSACTIONS_BATCHES = 'TRANSACTIONS_BATCHES',
  PAYMENTS_PAYROLL_MANUAL = 'PAYMENTS_PAYROLL_MANUAL',
  PAYMENTS_PROVIDERS_MANUAL = 'PAYMENTS_PROVIDERS_MANUAL',
  PAYMENTS_PAYROLL_BATCHES = 'PAYMENTS_PAYROLL_BATCHES',
  PAYMENTS_PROVIDERS_BATCHES = 'PAYMENTS_PROVIDERS_BATCHES',
  TRANSACTIONS_INSCRIBED_ACCOUNTS = 'TRANSACTIONS_INSCRIBED_ACCOUNTS',
  PSE = 'PSE',
  CASHBACK = 'CASHBACK',
  RECAUDO = 'RECAUDO',
  PRODUCT_TRANSFERS = 'EPREPAID_BUDGET_MANAGEMENT',
}

export enum TransactionChannel {
  IRIS_TO_SIIF = 'iris_to_siif_companies',
}

export enum TransactionStatus {
  SCHEDULED = 'SCHEDULED',
  PROCESSED = 'PROCESSED',
  CANCELED = 'CANCELED',
  REFUSED_ACH = 'REFUSED_ACH',
  WAITING_AUTHORIZATION = 'WAITING_AUTHORIZATION',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  REJECTED_FRAUD_MONITORING  = 'REJECTED_FRAUD_MONITORING',
  FRAUD_MONITORING_VALIDATION   = 'FRAUD_MONITORING_VALIDATION',
}

export enum IrisPayPaymentMethod{
  CASH = 'cash',
  TRANSFER = 'transfer'
}

export interface CreateTransaction {
  originEncodedKey: string;
  amount: number;
  details: string;
  destination: DestinationAccount;
  schedule: Schedule;
  inscribeAccount: boolean;
}

export interface TransactionFee {
  amount: number;
}

export interface MambuTransactionViewModel {
  date: string;
  encodedKey: string;
  originAccountTitle: string;
  originAccountDetails: string;
  descriptionTitle: string;
  descriptionDetails: string;
  entityReference?: string;
  detailsTitle?: string;
  detailsDescription?: string;
  destinationAccountDetails?: string;
  reference: string;
  tag?: Tag;
  user?: string;
  amount: number;
  hasReceipt?: boolean;
  totalPayment?: number;
  cardPayment: CardPaymentViewModel;
}
