import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@iris/iris-authentication';
import { WrapperComponent } from './base/wrapper/wrapper.component';
import { RefreshComponent } from './refresh/refresh.component';
import { EmptyComponent } from "./empty/empty.component";

const routes: Routes = [
  // Public routes
  {
    path: 'refresh',
    component: RefreshComponent,
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  // Private routes
  {
    path: '',
    component: WrapperComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'account-details',
        loadChildren: () =>
          import('./account-details/account-details.module').then(
            m => m.AccountDetailsModule,
          ),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import('./user-management/user-management.module').then(
            m => m.UserManagementModule,
          ),
      },
      {
        path: 'transfer',
        loadChildren: () =>
          import('./transfer/transfer.module').then(m => m.TransferModule),
      },
      {
        path: 'payment',
        loadChildren: () =>
          import('./payment/payment.module').then(m => m.PaymentModule),
      },
      {
        path: 'administration',
        loadChildren: () =>
          import('./administration/administration.module').then(
            m => m.AdministrationModule,
          ),
      },
      {
        path: 'pending-transactions',
        loadChildren: () =>
          import('./pending-transactions/pending-transactions.module').then(
            m => m.PendingTransactionsModule,
          ),
      },
      {
        path: 'transactions-history',
        loadChildren: () =>
          import('./transactions-history/transactions-history.module').then(
            m => m.TransactionsHistoryModule,
          ),
      },
      {
        path: 'operations-history',
        loadChildren: () =>
          import('./operations-history/operations-history.module').then(
            m => m.OperationsHistoryModule,
          ),
      },
      {
        path: 'operations-history-control-account',
        loadChildren: () =>
        import('./operations-history-control-account/operations-history-control-account.module').then(
          m => m.OperationsHistoryControlAccountModule,
        ),
      },
      {
        path: 'plans-management',
        loadChildren: () =>
        import('./administration/plans-management/plans-management.module').then(
          m => m.PlansManagementModule
        )
      },
      {
        path: '**',
        component: EmptyComponent
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
