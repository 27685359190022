import { AsyncOperationService } from './async/async-operation.service';
import { AuthorizationsService } from './authorizations/authorizations.service';
import { BankService } from './bank/bank.service';
import { BatchService } from './batch/batch.service';
import { BillingService } from './billing/billing.service';
import { UserHasPermissionService } from './common/user-has-permission.service';
import { DepositAccountService } from './depositAccount/depositAccount.service';
import { ExportService } from './export/export.service';
import { FileDownloaderService } from './file/file-downloader.service';
import { GroupService } from './group/group.service';
import { InscribedAccountsService } from './inscribedAccounts/inscribed-accounts.service';
import { MenuService } from './menu/menu.service';
import { RoleMapperService } from './roles/role-mapper.service';
import { AreasService } from './areas/areas.service';
import { RoleService } from './roles/roles.service';
import { SettingsService } from './settings/settings.service';
import { CategoriesService } from './tag/categories.service';
import { TagService } from './tag/tag.service';
import { PendingTransactionService } from './transaction/pending.transaction.service';
import { TransactionService } from './transaction/transaction.service';
import { TeamService } from './teams/teams.service';
import { UserMapperService } from './user/user-mapper.service';
import { UserService } from './user/user.service';
import { AddOnsService } from './addOns/add-ons.service';
import { DepositService } from './deposit/deposit.service';

export const ServicesInjection = [
  AuthorizationsService,
  BatchService,
  FileDownloaderService,
  GroupService,
  RoleService,
  AreasService,
  TagService,
  PendingTransactionService,
  TransactionService,
  TeamService,
  UserMapperService,
  RoleMapperService,
  BankService,
  DepositAccountService,
  InscribedAccountsService,
  UserService,
  AsyncOperationService,
  UserHasPermissionService,
  ExportService,
  MenuService,
  SettingsService,
  CategoriesService,
  AddOnsService,
  DepositService,
  BillingService,
];
