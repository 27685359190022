import { Injectable } from '@angular/core';
import { NetworkService, StatusCodes } from '@iris/iris-base';
import {
  WebSocketConnectionService,
  WebsocketOperation,
} from '@iris/iris-authentication';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AsyncOperationType } from 'src/lib/models/async.model';
import {
  filterAsyncResponse,
  filterWebsocketOperation,
  mapAsyncResponse,
} from 'src/lib/operators/async-response.operator';

@Injectable()
export class AsyncOperationService {
  constructor(
    private websocketService: WebSocketConnectionService,
    private networkService: NetworkService,
  ) {}

  public listenAsyncResponse<T>(
    uuid: string,
    asyncType: AsyncOperationType,
  ): Observable<T> {
    return this.websocketService.messaging$.pipe(
      filterWebsocketOperation(WebsocketOperation.ASYNC_OPERATION_RESPONSE),
      mapAsyncResponse<T>(),
      tap(r => {
        if (r.status === StatusCodes.InternalServerError) {
          this.networkService.requestFailed({
            status: r.status,
          } as any);

          throw r;
        }
      }),
      filterAsyncResponse(uuid, asyncType),
      map(r => r.result),
    );
  }
}
