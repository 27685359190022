import { Pipe, PipeTransform } from '@angular/core';
import { InscribedAccount } from '../models';

@Pipe({
  name: 'depositaccountmask',
})
export class DepositAccountMaskPipe implements PipeTransform {
  transform(accountId: string): string {
    if (!accountId) {
      return null;
    }
    if (accountId.length <= 4) {
      return accountId;
    }
    return accountId.substring(accountId.length - 4).padStart(8, '•');
  }
}
