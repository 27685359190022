import { Directive, HostListener, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import { UserDocumentType } from '../models';
import { isLetterOrNumber } from '../operators/keyboard.operator';

export const defaultMaxLength = 15;

export function validDocumentId(documentType: UserDocumentType): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const documentId = control.value;
    const documentIdLength = documentId?.length;

    if (
      !documentIdLength ||
      !/^[a-zA-Z0-9]*$/.test(documentId)
    )
      return { invalid: true };
    else if (
      /^[0]+[0-9a-zA-Z]*$/.test(documentId)
    )
      return { documentBeginsWithZero: true };

    switch (documentType) {
      case UserDocumentType.CedulaCiudadania:
        if (
          documentIdLength !== 6 &&
          documentIdLength !== 7 &&
          documentIdLength !== 8 &&
          documentIdLength !== 10
        )
          return { invalid: true };
        break;

      case UserDocumentType.CedulaExtranjeria:
        if (documentIdLength < 6 || documentIdLength > 7)
          return { invalid: true };
        break;
      case UserDocumentType.Passport:
        if (documentIdLength < 5 || documentIdLength > 15)
          return { invalid: true };
        break;
      case UserDocumentType.NIT:
      case UserDocumentType.Fideicomiso:
      case UserDocumentType.TarjetaIdentidad:
      case UserDocumentType.RegistroCivil:
      case UserDocumentType.ForeignId:
      case UserDocumentType.DiplomaticId:
        const maxLength = getDocumentMaxLength(documentType);
        if (documentIdLength !== maxLength) return { invalid: true };
        break;
      default:
        if (documentIdLength > defaultMaxLength) {
          return { invalid: true };
        }
        break;
    }
    return null;
  };
}

@Directive({
  selector: '[validDocumentId]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DocumentIdDirective,
      multi: true,
    },
  ],
})
export class DocumentIdDirective implements Validator {
  @Input('validDocumentId') documentType: UserDocumentType;
  validate(control: AbstractControl): { [key: string]: any } | null {
    return validDocumentId(this.documentType)(control);
  }

  @HostListener('keypress', ['$event']) public onKeydown(event): void {
    let maxLength = getDocumentMaxLength(this.documentType);
    const target = event.target as any;

    if (event.keyCode === 32) {
      event.preventDefault();
      return;
    }

    if (
      this.documentType !== UserDocumentType.Passport &&
      /[a-zA-ZÀ-ÖØ-öø-ÿçÇ'"´`^~¨|\\]/.test(String.fromCharCode(event.keyCode))
    ) {
      event.preventDefault();
      return;
    }
    if (isLetterOrNumber(event)) {
      const value = target.value;
      if (value.length === maxLength) {
        event.preventDefault();
      }
    }
  }
}

export function getDocumentMaxLength(documentType: UserDocumentType): number {
  switch (documentType) {
    case UserDocumentType.CedulaExtranjeria:
      return 7;
    case UserDocumentType.NIT:
    case UserDocumentType.Fideicomiso:
      return 9;
    case UserDocumentType.CedulaCiudadania:
    case UserDocumentType.TarjetaIdentidad:
    case UserDocumentType.RegistroCivil:
      return 10;
    case UserDocumentType.Passport:
    case UserDocumentType.ForeignId:
    case UserDocumentType.DiplomaticId:
    default:
      return defaultMaxLength;
  }
}
