import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '@iris/iris-authentication';
import { NavItem, Submenu } from 'src/lib/models/navigation.model';
import { UserHasPermissionService } from '../common/user-has-permission.service';
import { RolePermissions } from '../roles/role.types';

@Injectable()
export class MenuService {
  menus: NavItem[] = [
    {
      href: '/dashboard',
      title: 'Dashboard',
      icon: 'assets/icons/menu/ic-resume.svg',
      hasAccess: false,
      permissions: [RolePermissions.COMPLETE_SUMMARY],
    },
    {
      title: 'Transfers',
      icon: 'assets/icons/menu/ic-transferencias.svg',
      href: '/transfer',
      hasAccess: false,
      permissions: [
        RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
        RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
        RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
        RolePermissions.TRANSACTIONS_BATCHES,
      ],
      submenu: [
        {
          title: 'Transfers.InscribedAccounts.Title',
          href: '/transfer/inscribed-accounts',
          permissions: [RolePermissions.TRANSACTIONS_INSCRIBED_ACCOUNTS],
          hasAccess: false,
          image: 'img-cuentas-inscritas',
        },
        {
          title: 'Transfers.Transfer.OwnIrisAccounts',
          href: '/transfer/own-iris',
          permissions: [RolePermissions.TRANSACTIONS_OWN_ACCOUNTS],
          hasAccess: false,
          image: 'img-cuentas-propias',
        },
        {
          title: 'Transfers.Transfer.OtherIrisAccounts',
          href: '/transfer/other-iris',
          permissions: [RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK],
          hasAccess: false,
          image: 'img-banco-iris',
        },
        {
          title: 'Transfers.Transfer.OtherBankAccounts',
          href: '/transfer/other-bank',
          permissions: [RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK],
          hasAccess: false,
          image: 'img-otros-bancos',
        },
        {
          title: 'Transfers.Batch.Title',
          href: '/transfer/batch-transfer',
          permissions: [RolePermissions.TRANSACTIONS_BATCHES],
          hasAccess: false,
          hideOnMobile: true,
          image: 'img-en-lote',
        },
      ],
    },
    {
      title: 'Payments',
      icon: 'assets/icons/menu/ic-pagos.svg',
      href: '/payment',
      hasAccess: false,
      permissions: [
        RolePermissions.PAYMENTS_PAYROLL_MANUAL,
        RolePermissions.PAYMENTS_PROVIDERS_MANUAL,
        RolePermissions.PAYMENTS_PAYROLL_BATCHES,
        RolePermissions.PAYMENTS_PROVIDERS_BATCHES,
      ],
      submenu: [
        {
          title: 'Payments.Tabs.CSV',
          // href: '/payment/csv',
          href: '',
          permissions: [
            RolePermissions.PAYMENTS_PAYROLL_BATCHES,
            RolePermissions.PAYMENTS_PROVIDERS_BATCHES,
          ],
          hasAccess: false,
          image: 'img-pagos-en-lote',
        },
        {
          title: 'Payments.Tabs.MANUAL',
          href: '/payment/manual',
          permissions: [
            RolePermissions.PAYMENTS_PAYROLL_MANUAL,
            RolePermissions.PAYMENTS_PROVIDERS_MANUAL,
          ],
          hasAccess: false,
          image: 'img-pagos-manuales',
        },
        {
          title: 'Payments.Tabs.DRAFT',
          href: '/payment/draft',
          permissions: [
            RolePermissions.PAYMENTS_PAYROLL_MANUAL,
            RolePermissions.PAYMENTS_PROVIDERS_MANUAL,
          ],
          hasAccess: false,
          image: 'img-pagos-guardados',
        },
      ],
    },
    {
      title: 'PendingTransactions',
      icon: 'assets/icons/menu/ic-pending-transactions.svg',
      permissions: [
        RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
        RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
        RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
        RolePermissions.TRANSACTIONS_BATCHES,
        RolePermissions.CARDS_MANAGEMENT,
      ],
      href: '/pending-transactions',
      hasAccess: false,
      submenu: [
        {
          title: 'PendingTransactions.Authorization',
          href: 'pending/requested',
          permissions: [
            RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
            RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
            RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
            RolePermissions.TRANSACTIONS_BATCHES,
            RolePermissions.CARDS_MANAGEMENT,
          ],
          hasAccess: false,
          image: 'img-autorizacion',
        },
        {
          title: 'Transactions.ScheduledTransactions.Title',
          href: 'scheduled/transactions',
          permissions: [
            RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
            RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
            RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
            RolePermissions.TRANSACTIONS_BATCHES,
          ],
          hasAccess: false,
          image: 'img-programadas',
        },
      ],
    },
    {
      title: 'TransactionsHistory',
      icon: 'assets/icons/menu/ic-gestion-de-transacciones.svg',
      hasAccess: false,
      href: '/transactions-history/transactions',
      permissions: [
        RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
        RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
        RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
        RolePermissions.TRANSACTIONS_BATCHES,
      ],
    },
    {
      title: 'AccountManagement',
      icon: 'assets/icons/menu/ic-gesion-de-usuario.svg',
      href: '/user-management',
      permissions: [
        RolePermissions.USERS,
        RolePermissions.ROLE_AND_LIMITS,
        RolePermissions.USER_LOGS,
        RolePermissions.AREAS_AND_TEAMS,
      ],
      hasAccess: false,
      submenu: [
        {
          title: 'AccountManagement.RoleManagement.Title',
          href: 'roles',
          hasAccess: false,
          permissions: [RolePermissions.ROLE_AND_LIMITS],
          image: 'img-roles-y-limites',
        },
        {
          title: 'AccountManagement.AreaManagement.Title',
          href: 'areas',
          hasAccess: false,
          permissions: [RolePermissions.AREAS_AND_TEAMS],
          image: 'img-areas-y-equipos',
        },
        {
          title: 'AccountManagement.UserManagement.Title',
          href: 'users',
          permissions: [RolePermissions.USERS],
          hasAccess: false,
          image: 'img-cuentas-de-usuarios',
        },
        {
          title: 'Administration.Logs.Title',
          href: 'logs',
          hasAccess: false,
          permissions: [RolePermissions.USER_LOGS],
          image: 'img-log-de-usuarios',
        },
      ],
    } /*
      {
        title: 'Documents',
        icon: 'assets/icons/menu/ic-documents.svg',
        href: '/documents',
        hasAccess: true,
      },*/,
    {
      title: 'Administration',
      icon: 'assets/icons/menu/ic-administracion.svg',
      href: '/administration',
      hasAccess: true,
      submenu: [
        {
          title: 'Administration.ChangePassword.Title',
          href: 'change-password',
          hasAccess: true,
          image: 'img-cambiar-contrasena',
        },
        {
          title: 'Administration.Tags.Tabs.Transactions',
          href: 'tags/transactions',
          hasAccess: false,
          permissions: [RolePermissions.IDENTIFICATION_TAGS],
          image: 'img-tag-de-identificacion',
        },
        {
          title: 'Administration.Tags.Tabs.Accounts',
          href: 'tags/accounts',
          hasAccess: false,
          permissions: [RolePermissions.IDENTIFICATION_ACCOUNTS],
          image: 'img-identificacion-de-cuentas',
        },
        {
          title: 'Administration.Plans.Title',
          href: 'plans/account-selector',
          hasAccess: false,
          permissions: [RolePermissions.PLANS],
          image: 'img-plans',
          disabled: true,
        },
        {
          title: 'Administration.Notifications.Title',
          href: 'notifications',
          hasAccess: false,
          permissions: [RolePermissions.NOTIFICATIONS],
          image: 'img-notificaciones',
        },
      ],
    },
  ];
  constructor(
    private hasPermissionService: UserHasPermissionService,
    private sessionService: SessionService
  ) {
    this.handleUserPermissions();
  }

  handleUserPermissions() {
    this.menus.map(menu => {
      if (menu.permissions?.length > 0) {
        menu.hasAccess = this.hasPermissionService.hasPermission(
          menu.permissions,
        );
      }
      menu.submenu?.forEach(async submenu => {
        if (submenu.permissions?.length > 0) {
          submenu.hasAccess = this.hasPermissionService.hasPermission(
            submenu.permissions,
          );
        }
      });
    });
  }

  async getMenus(): Promise<NavItem[]> {
    await this.sessionService.refreshToken();
    this.handleUserPermissions();
    return this.menus;
  }

  async getSubMenu(menuTitle: string): Promise<Submenu[]> {
    this.handleUserPermissions();
    return this.menus.find(menu => menu.title === menuTitle).submenu;
  }
}
