export const environment = {
  production: false,
  idleTimeout: 5,
  eprepaidApi: 'https://api.dev.irsbnk.co/iriscard',
  transactionsApi:
    'https://api.dev.irsbnk.co/empresas-financial-1/transactions',
  notificationsApi: 'https://api.dev.irsbnk.co/empresas-1/notifications',
  depositAccountsApi:
    'https://api.dev.irsbnk.co/empresas-financial-1/depositaccounts',
  dailyBalancesApi:
    'https://api.dev.irsbnk.co/empresas-financial-1/dailybalances',
  accountApi: 'https://api.dev.irsbnk.co/empresas-1/account',
  userApi: 'https://api.dev.irsbnk.co/empresas-1/users',
  approvalEprepaidApi: 'https://api.dev.irsbnk.co/eprepaid/approval',
  groupApi: 'https://api.dev.irsbnk.co/empresas-financial-1/groups',
  roleApi: 'https://api.dev.irsbnk.co/empresas-1/roles',
  otpApi: 'https://api.dev.irsbnk.co/empresas-1/otp',
  banksApi: 'https://api.dev.irsbnk.co/empresas-financial-1/banks',
  tagApi: 'https://api.dev.irsbnk.co/empresas-1/tags',
  registerAccountsApi:
    'https://api.dev.irsbnk.co/empresas-financial-1/inscribedaccounts',
  batchApi: 'https://api.dev.irsbnk.co/empresas-financial-1/batches',
  logsApi: 'https://api.dev.irsbnk.co/empresas-1/auditlogs',
  authorizationsApi:
    'https://api.dev.irsbnk.co/empresas-financial-1/authorizations',
  exportsApi: 'https://api.dev.irsbnk.co/empresas-financial-1/exports',
  holidaysApi: 'https://api.dev.irsbnk.co/empresas-1/holidays',
  loginApi: 'https://api.dev.irsbnk.co/empresas-1/login',
  loansApi: 'https://api.dev.irsbnk.co/empresas-financial-1/loans',
  plansApi: 'https://api.dev.irsbnk.co/empresas-financial-1/plans',
  categoriesApi: 'https://api.dev.irsbnk.co/empresas-1/categories',
  billingApi: 'https://api.dev.irsbnk.co/irispay/billing',
  websocketApi: 'wss://apiv2.dev.irsbnk.co/empresas-websocket-1',
  staticApiKey: 'gA3QHPRfjJqYCJ6ymvUG5AuwzUxKAanaY5AOEcW1',
  sessionConfiguration: {
    domain: 'empresas-1.dev.iristesting.com.co',
    path: '/',
    expires: 365,
    secure: true,
    sameSite: 'strict',
  },
  addOnsApi: 'https://api.dev.irsbnk.co/empresas-complements/complements',
  depositsApi: 'https://api.dev.irsbnk.co/empresas-financial-1/deposits',
};
