import { NgModule } from '@angular/core';
import { BatchDetailsDirective } from '../../lib/validators/batch-details.directive';
import { TransferAmountDirective } from '../../lib/validators/transfer-amount.directive';
import { DocumentIdDirective } from '../../lib/validators/document-id.directive';
import { DateRangeDirective } from '../../lib/validators/date-range.directive';

const directives = [
  BatchDetailsDirective,
  TransferAmountDirective,
  DocumentIdDirective,
  DateRangeDirective,
];

@NgModule({
  declarations: directives,
  imports: [],
  exports: directives,
})
export class DirectivesModule {}
