import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ResumePagination, TagSummaryFilterModel } from 'src/app/administration/tags/components/tags-resume-card/components/iris-pay/components/tags-recaudo-resume/tags.resume.types';
import { BaseService } from '../base/base.service';
import { SettingsService } from '../settings/settings.service';

@Injectable()
export class BillingService extends BaseService {
  billingCreateNotifier$: Subject<void> = new Subject();

  constructor(
    protected http: HttpClient,
    protected settings: SettingsService
  ) {
    super(http, settings);
  }

  getAggregation(filters: TagSummaryFilterModel): Observable<ResumePagination> {
    return this.http.get(`${this.settings.get.billingApi}/aggregation`, {
      params: filters as any
    });
  }

}
